import React, { useState, useEffect } from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Table, Input, Button, Modal, Form, Select,Descriptions } from 'antd';
import { AddLivestock, GetLivestockList, putLivestock, deleteLivestock, GetPropertyList } from "../action/authAction";

const LivesStock = () => {
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 20, span: 16 },
    };
    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            width: '5%',
            align: "center",
        },
        {
            title: 'Property',
            width: '10%',
            align: "center",
            render: (record) => {
                return record.reportField&&record.reportField.property && record.reportField.property.name
            }
        },
        {
            title: 'Stock Classification',
            width: '12%',
            align: "center",
            render: (record) => {
                return record.reportField&&record.reportField.stock_classification
            }
        },
        {
            title: 'Muster Number',
            width: '12%',
            align: "center",
            render: (record) => {
                return record.reportField&&record.reportField.muster_number
            }
        },
        {
            title: 'Muster Percentage',
            width: '12%',
            align: "center",
            render: (record) => {
                return record.reportField&&record.reportField.muster_percentage
            }
        },
        {
            title: 'Source',
            width: '12%',
            align: "center",
            render: (record) => {
                return record.reportField&&record.reportField.source
            }
        },
        {
            title: 'Mortality Estimate',
            width: '12%',
            align: "center",
            render: (record) => {
                return record.reportField&&record.reportField.mortality_estimate
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: '20%',
            align: "center",
            render: (text, record) => {
                return <div>
                    <Button type="link" style={{ marginRight: "10px" }}
                        onClick={() => {
                            setNewVisible(false)
                            setEditVisible(true)
                            setReportField(record.reportField)
                            setId(record.id)
                            setPropertyId(record.reportField&&record.reportField.property_id)
                            setStock(record.reportField&&record.reportField.stock_classification)
                            setSource(record.reportField&&record.reportField.source)
                        }}
                    >Edit</Button>
                    <Button type="link" style={{ marginLeft: "10px" }} onClick={() => handleDelete(record.id)}>Delete</Button>
                </div>
            },
        },

    ];

    const [data, setData] = useState([])
    const [NewVisible, setNewVisible] = useState(false)
    const [EditVisible, setEditVisible] = useState(false)
    const [ReportField, setReportField] = useState([])
    const [Id, setId] = useState([])
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [PropertyList, setPropertyList] = useState([])
    const [StockList] = useState(["Bush Cows", "Herd bulls", "Calves", "Weaners Heifers", "Mixed cattle"])
    const [SourceList] = useState(["Sales", "Purchases", "Trucked from another property", "Agisted Cattle for Fattening"])
    const [property_id, setPropertyId] = useState('')
    const [Stock, setStock] = useState('')
    const [Source, setSource] = useState('')

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        GetLivestockList().then(res => {
            setData(res.data.data)
        }).catch(error=>{
            
        })
        GetPropertyList().then(res => {
            setPropertyList(res.data.data)
        })

    }

    const handleDelete = (id) => {
        deleteLivestock(id).then(res => {
            getData()
        })
    }

    const onFinish = (values) => {
        let report_field = {}
            report_field["report_field"] = {}
            values.property_id = property_id
            values.stock_classification = Stock
            values.source = Source
            for (let i in values) {
                report_field["report_field"][i] = values[i];
            }
        if (EditVisible) {
            putLivestock(report_field, Id).then(res => {
                getData()
                setEditVisible(false)
            })
        } else {
            
            AddLivestock(report_field).then(res => {
                getData()
                setNewVisible(false)
            }).catch(error => {

            })
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Button type="primary" onClick={() => {
                    setNewVisible(true)
                }} style={{ marginRight: "10px" }}>
                    <PlusOutlined /> New
                </Button>
            </div>
            <Table
                columns={columns}
                expandable={{
                    expandedRowRender: record => {
                        let reportField = record.reportField
                        return <Descriptions
                            title="Detail"
                            size="default"
                            style={{ marginLeft: "70px" }}
                        >
                            <Descriptions.Item label="Property">{reportField.property && reportField.property.name}</Descriptions.Item>
                            <Descriptions.Item label="Stock Classification">{reportField.stock_classification}</Descriptions.Item>
                            <Descriptions.Item label="Muster Number">{reportField.muster_number}</Descriptions.Item>
                            <Descriptions.Item label="Muster Percentage">{reportField.muster_percentage}</Descriptions.Item>
                            <Descriptions.Item label="Source">{reportField.source}</Descriptions.Item>
                            <Descriptions.Item label="Mortality Estimate">{reportField.mortality_estimate}</Descriptions.Item>
                            <Descriptions.Item label="Created by">{reportField.create_by}</Descriptions.Item>
                            <Descriptions.Item label="Updated by">{reportField.update_by}</Descriptions.Item>
                            <Descriptions.Item label="Created at">{reportField.created_at && reportField.created_at.date.substring(0, reportField.created_at.date.length - 7)}</Descriptions.Item>
                            <Descriptions.Item label="Updated at">{reportField.updated_at && reportField.updated_at.date.substring(0, reportField.created_at.date.length - 7)}</Descriptions.Item>
                        </Descriptions>
                    },
                    onExpand: (e, record) => {
                        let isClose = JSON.stringify(expandedRowKeys) === JSON.stringify([record.id])
                        setExpandedRowKeys(!isClose ? [record.id] : [])
                    },
                    expandedRowKeys: expandedRowKeys
                }}
                dataSource={data}
                rowKey={(record) => record.id}
            />
            {
                NewVisible &&
                <Modal
                    title="New"
                    visible={NewVisible}
                    onCancel={() => setNewVisible(false)}
                    footer={null}
                >
                    <Form
                        {...layout}
                        name="basic"
                        onFinish={onFinish}
                    >
                        <Form.Item label="Property">
                            <Select
                                onChange={(value) => setPropertyId(value)}
                            >
                                {
                                    PropertyList.map((item, index) => {
                                        return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item label="stock">
                            <Select
                                onChange={(value) => setStock(value)}
                            >
                                {
                                    StockList.map((item, index) => {
                                        return <Select.Option key={index} value={item}>{item}</Select.Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item label="Source">
                            <Select
                                onChange={(value) => setSource(value)}
                            >
                                {
                                    SourceList.map((item, index) => {
                                        return <Select.Option key={index} value={item}>{item}</Select.Option>
                                    })
                                }

                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Percentage"
                            name="muster_percentage"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Number"
                            name="muster_number"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Mortality"
                            name="mortality_estimate"
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            }
            {
                EditVisible &&
                <Modal
                    title="Edit"
                    visible={EditVisible}
                    onCancel={() => {
                        setPropertyId('')
                        setStock('')
                        setSource('')
                        setEditVisible(false)
                    }}
                    footer={null}
                >
                    <Form
                        {...layout}
                        name="basic"
                        onFinish={onFinish}
                    >
                        <Form.Item label="Property">
                            <Select
                                defaultValue={ReportField.property&&ReportField.property.name}
                                onChange={(value) => setPropertyId(value)}
                            >
                                {
                                    PropertyList.map((item, index) => {
                                        return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item label="stock">
                            <Select
                                defaultValue={ReportField.stock_classification}
                                onChange={(value) => setStock(value)}
                            >
                                {
                                    StockList.map((item, index) => {
                                        return <Select.Option key={index} value={item}>{item}</Select.Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        <Form.Item label="Source">
                            <Select
                                defaultValue={ReportField.source}
                                onChange={(value) => setSource(value)}
                            >
                                {
                                    SourceList.map((item, index) => {
                                        return <Select.Option key={index} value={item}>{item}</Select.Option>
                                    })
                                }

                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Percentage"
                            name="muster_percentage"
                            initialValue={ReportField.muster_percentage}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Number"
                            name="muster_number"
                            initialValue={ReportField.muster_number}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Mortality"
                            name="mortality_estimate"
                            initialValue={ReportField.mortality_estimate}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </div>
    );
}

export default LivesStock

