import React, {Component} from 'react'
import {HashRouter, Switch, Route, Redirect} from 'react-router-dom'
import {getTokenStorage} from "./util/function";


import Login from "../src/page/login/index"
import Admin from "./admin";

import EditUser from "./page/user";
import LiveStock from "./page/livestock";
import Report from "./page/report";
import ReportDetail from "./page/reportDetail";
import Feeds from "./page/feeds";
import FeedList from "./page/feedlist";
import Multiple from "./page/multiple";





class Router extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route path='/' exact>
                        {
                            getTokenStorage()?
                                <Redirect to='/feeds'/>:
                                <Redirect to='/login'/>
                        }

                    </Route>
                    <Route path='/login' component={Login}/>
                    <Route path='/feeds' component={Feeds}/>
                    <Route
                        render={() => {
                            return (
                                <Admin>
                                    <Switch>
                                        <Route path='/user' component={EditUser} />
                                        <Route path='/livestock' component={LiveStock} />
                                        <Route path='/report' component={Report} />
                                        <Route path='/reportdetail' component={ReportDetail} />
                                        <Route path='/multiple' component={Multiple} />
                                        <Route path='/feedlist' component={FeedList} />

                                    </Switch>
                                </Admin>
                            )
                        }}
                    />
                </Switch>
            </HashRouter>
        )
    }
}

export default Router
