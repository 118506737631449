import React, { useState, useEffect } from "react";
import { Table, Button } from 'antd';
import { GetFeedList, deleteFeed } from "../action/authAction";

const LivesStock = () => {

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            width: '5%',
            align: "center",
        },
        {
            title: 'Created By',
            dataIndex: 'create_by',
            width: '5%',
            align: "center",

        },

        {
            title: 'Title',
            width: '10%',
            align: "center",
            render: (record) => {
                return <div style={{textAlign:"left"}}>{record.detail}</div>
            }

        },
        {
            title: 'Created at',
            width: '15%',
            align: "center",
            render: (record) => {
                return record.created_at && record.created_at.date.substring(0, record.created_at.date.length - 7)
            }
        },
        {
            title: 'Number of pictures',
            width: '10%',
            align: "center",
            render: (record) => {
                return record.feedResourceImage.data.length
            }
        },
        {
            title: 'Number of videos',
            width: '10%',
            align: "center",
            render: (record) => {
                return record.feedResourceVideo.data.length
            }
        },
        {
            title: 'Number of documents',
            width: '10%',
            align: "center",
            render: (record) => {
                return record.feedResourceFile.data.length
            }
        },
        {
            title: 'Audit Status',
            width: '10%',
            align: "center",
            render: (record) => {
                if (record.status) {
                    return <span style={{ color: "green" }}>yes</span>
                }
                return <span style={{ color: "#ff0000" }}>no</span>
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            align: "center",
            render: (text, record) => {
                if (!record.status) {
                    return <Button type="link" style={{ marginLeft: "10px" }} onClick={() => handleDelete(record.id)}>Delete</Button>
                }
            },
        },

    ];

    const [data, setData] = useState([])


    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        GetFeedList().then(res => {
            setData(res.data.data)
        })

    }

    const handleDelete = (id) => {
        deleteFeed(id).then(res => {
            getData()
        })
    }

    return (
        <div style={{ width: "100%" }}>
            <Table
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
            />
        </div>
    );
}

export default LivesStock

