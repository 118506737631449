export const API = {
    BASEURL: "https://wformapi.commonworths.com/api/",
    auth: {
        LOGIN: "login",
        Update: "update",
        User: "user",
    },
    Livestock:"livestock",
    Report:"report",
    Property:"property",
    SubmitLiveStock:"submitLiveStock",
    Feed:"feed",
    feedResource:"feedResource"

}


