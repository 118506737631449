import React, { useState, useEffect } from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Table,Button} from 'antd';
import { Link } from "react-router-dom"
import { AddReport, GetReportList, deleteReport } from "../action/authAction";

const LivesStock = () => {

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            width: '5%',
            align: "center",
        },
        {
            title: 'Report Name',
            dataIndex: 'name',
            width: '10%',
            align: "center",

        },
        {
            title: 'Status',
            dataIndex: 'status',
            width: '10%',
            align: "center",

        },
        {
            title: 'Reporter',
            dataIndex: 'reporter',
            width: '10%',
            align: "center",

        },
        {
            title: 'Feedback',
            dataIndex: 'feedback',
            width: '10%',
            align: "center",

        },
        {
            title: 'Action',
            key: 'action',
            width: '20%',
            align: "center",
            render: (text, record) => {
                let path = `/reportdetail?id=${record.id}`
                return <div>
                    <Link to={path}>Edit</Link>
                    <Button type="link" style={{ marginLeft: "10px" }} onClick={() => handleDelete(record.id)}>Delete</Button>
                </div>
            },
        },

    ];

    const [data, setData] = useState([])


    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        GetReportList().then(res => {
            setData(res.data.data)
        })

    }

    const handleDelete = (id) => {
        deleteReport(id).then(res => {
            getData()
        })
    }

    const onFinish = () => {
        AddReport().then(res => {
            let path = `#/reportdetail?id=${res.data.id}`
            window.location.href = path
        }).catch(error => {

        })
    };

    return (
        <div style={{ width: "100%" }}>
            <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <Button type="primary" onClick={() => {
                    // setNewVisible(true)
                    onFinish()

                }} style={{ marginRight: "10px" }}>
                    <PlusOutlined /> New
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
            />
        </div>
    );
}

export default LivesStock

