import { Axios } from "../util/request"
import { API } from "../util/api";
import { type } from "../util/varibales";
import axios from 'axios'


let key = "AIzaSyCJcl0eepyhlcz2SnuTRIlPVq7SIbrTglY"
const gtoken = localStorage.getItem("gtoken")
export const SetMenuName = (name) => {
    return (dispatch) => {
        dispatch({
            type: type.SWITCH_MENU,
            payload: name
        });
    }
}

// 登录
export const login = (data) => {
    return Axios.ajax({
        url: API.auth.LOGIN,
        method: "post",
        data
    })
}
// 修改用户信息
export const UpdateUser = (data) => {
    return Axios.ajax({
        url: API.auth.User + "/" + API.auth.Update,
        method: "post",
        data
    })
}

// 获取用户信息
export const GetUserDetail = () => {
    return Axios.ajax({
        url: API.auth.User,
        method: "get"
    })
}



// 获取Property列表
export const GetPropertyList = () => {
    return Axios.ajax({
        url: API.Property,
        method: "get"
    })
}



// 新建Livestock
export const AddLivestock = (data) => {
    return Axios.ajax({
        url: API.Livestock,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取Livestock列表
export const GetLivestockList = () => {
    return Axios.ajax({
        url: API.Livestock,
        method: "get"
    })
}


// 修改Livestock
export const putLivestock = (data, id) => {
    return Axios.ajax({
        url: API.Livestock + "/" + id,
        method: "put",
        tips: "修改成功",
        data,
    })
}

// 删除Livestock
export const deleteLivestock = (id) => {
    return Axios.ajax({
        url: API.Livestock + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}

// 新建Report
export const AddReport = (data) => {
    return Axios.ajax({
        url: API.Report,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取Report列表
export const GetReportList = () => {
    return Axios.ajax({
        url: API.Report,
        method: "get"
    })
}


// 修改Report
export const putReport = (data, id) => {
    return Axios.ajax({
        url: API.Report + "/" + id,
        method: "put",
        tips: "修改成功",
        data,
    })
}

// 删除Report
export const deleteReport = (id) => {
    return Axios.ajax({
        url: API.Report + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}


// 提交LiveStock
export const SubmitLiveStock = (data) => {
    return Axios.ajax({
        url: API.SubmitLiveStock,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取Report详情
export const GetReportDetail = (id) => {
    return Axios.ajax({
        url: API.Report + "/" + id,
        method: "get"
    })
}


// 新建Feed
export const AddFeed = (data) => {
    return Axios.ajax({
        url: API.Feed,
        method: "post",
        tips: "提交成功",
        data
    })
}

// 获取Feed列表
export const GetFeedList = (page) => {
    return Axios.ajax({
        url: API.Feed + "?page=" + page,
        method: "get"
    })
}



// 删除Feed
export const deleteFeed = (id) => {
    return Axios.ajax({
        url: API.Feed + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}


// 获取feedResource列表
export const GetFeedResourceList = (page) => {
    return Axios.ajax({
        url: API.feedResource + "?page=" + page,
        method: "get"
    })
}



// 上传youtube视频

// export const UploadYoutube = (data) => {

//     fetch("https://www.googleapis.com/upload/youtube/v3/videos?uploadType=resumable&part=snippet,status,contentDetails", {
//         method: "POST",
//         body:'{"snippet":{"categoryId":"22","description":"Description of uploaded video.","title":"Test video upload."},"status":{"privacyStatus":"private"}}',
//         // mode: 'cors',
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'Authorization': gtoken
//         }
//     }).then(res => {
//         console.log(res)
//     }).catch(error => {
//         console.log(error)
//     })
// }

// 上传youtube视频

export const UploadYoutube = (data) => {

    axios("https://www.googleapis.com/upload/youtube/v3/videos?uploadType=resumable&part=snippet,status,contentDetails", {
        method: "POST",
        body:'{"snippet":{"categoryId":"22","description":"Description of uploaded video.","title":"Test video upload."},"status":{"privacyStatus":"private"}}',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': gtoken
        }
    }).then(res => {
        console.log(res)
    }).catch(error => {
        console.log(error.response)
    })
}



// 获取youtube视频

export const GetYoutube = () => {

    fetch("https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&myRating=like&key=" + key, {
        method: "GET",
        // mode: 'cors',
        headers: new Headers({
            'Accept': 'application/json',
            'Authorization': gtoken
        })
    }).then(res => {
        console.log(res)
    }).catch(error => {
        console.log(error.response)
    })
}