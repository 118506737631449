import React, { useState, useEffect } from "react";
import { DeleteOutlined, CloseOutlined, BorderlessTableOutlined } from '@ant-design/icons';
import { Menu, Input, Upload, Button, List, message, Tag, Spin, Select } from 'antd'
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom'
import fileImg from "../image/file.png"
import videImg from "../image/video.png"
import iconVideo from "../image/iconVideo.png"
import iconFile from "../image/iconFile.png"
import "../style/mixin.less"
import { AddFeed, GetFeedList, deleteFeed, GetPropertyList } from "../action/authAction"
const MenuItem = Menu.Item
const { TextArea, Search } = Input;
const { Option } = Select;


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}


const Feeds = () => {
    const getWindowSize = () => window.innerHeight
    const [AllList, setAllList] = useState([])
    const [ImageList, setImageList] = useState([])
    const [ImgFileList, setImgFileList] = useState([])
    const [otherFileList, setOtherFileList] = useState([])
    const [VideoFileList, setVideoFileList] = useState([])
    const [detail, setDetail] = useState("")
    const [Height, setHeight] = useState(getWindowSize());
    const [loading, setLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [FeedList, setFeedList] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    const [page, setPage] = useState(1)
    const [imgType] = useState(["image/jpeg", "image/png", "image/gif"])
    const [isEnterUrl, setIsEnterUrl] = useState(false)
    const [videoList, setVideList] = useState([])
    const [PropertiesList, setPropertiesList] = useState([])
    const [tagValue, setTagValue] = useState("")
    const [PropertiesIds, setPropertiesIds] = useState([])
    const handleResize = () => {
        setHeight(getWindowSize());
    };
    useEffect(() => {

        handleGetFeedList(1)
        GetPropertyList().then(res => {
            setPropertiesList(res.data.data)
        })
        // 监听
        window.addEventListener("resize", handleResize);
        // 销毁
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line
    }, [])


    const handleChange = async (info) => {
        let isLt10M = info.file.size / 1024 / 1024 < 150
        if (!isLt10M) {
            message.error("Upload Failed:  File Size limit is 150MB. At 30 Frames per second,  you can roughly take 90s video at 720P, or 20s at 4K.   It is recommended to record your video and verify its size before uploading.");
            return false;
        }
        let fileListData = info.fileList
        let imgUrlList = []
        let imgList = []
        let otherFile = []
        let VideoFile = []
        for (let i in fileListData) {
            let isHas = imgType.includes(fileListData[i].type)
            let videoNum = fileListData[i].type.indexOf("video")
            if (isHas) {
                let imgUrl = await getBase64(fileListData[i].originFileObj)
                imgUrlList.push(imgUrl)
                imgList.push(fileListData[i])
            } else if (videoNum > -1) {
                VideoFile.push(fileListData[i])
            } else {
                otherFile.push(fileListData[i])
            }
        }

        setOtherFileList(otherFile)
        setImageList(imgUrlList)
        setImgFileList(imgList)
        setVideoFileList(VideoFile)
        setAllList(fileListData)
    }

    const handleDelete = (index) => {
        let arrImg = [...ImageList]
        let arrFile = ImgFileList
        let allFileList = AllList

        for (let i in allFileList) {
            if (allFileList[i].uid === arrFile[index].uid) {
                allFileList.splice(i, 1)
            }
        }
        arrImg.splice(index, 1)
        arrFile.splice(index, 1)
        setImageList(arrImg)
        setImgFileList(arrFile)
        setAllList(allFileList)
    }


    const handleOtherFileDelete = (index) => {
        let arrFile = [...otherFileList]
        let allFileList = AllList
        for (let i in allFileList) {
            if (allFileList[i].uid === arrFile[index].uid) {
                allFileList.splice(i, 1)
            }
        }
        arrFile.splice(index, 1)
        setOtherFileList(arrFile)
        setAllList(allFileList)
    }

    const handleVideoFileDelete = (index) => {
        let arrFile = [...VideoFileList]
        let allFileList = AllList

        for (let i in allFileList) {
            if (allFileList[i].uid === arrFile[index].uid) {
                allFileList.splice(i, 1)
            }
        }
        arrFile.splice(index, 1)
        setVideoFileList(arrFile)
        setAllList(allFileList)
    }

    const HandleSubmit = () => {
        setIsEnterUrl(false)
        let formData = new FormData();
        formData.append("detail", detail)
        if (ImgFileList.length > 0) {
            for (let i in ImgFileList) {
                formData.append('feed_resource_image[' + i + '][file]', ImgFileList[i].originFileObj);
            }
        }
        if (otherFileList.length > 0) {
            for (let i in otherFileList) {
                formData.append('feed_resource_file[' + i + '][file]', otherFileList[i].originFileObj);
            }
        }

        if (videoList.length > 0) {
            for (let i in videoList) {
                formData.append('feed_resource_video[' + i + '][url]', videoList[i]);
            }
        }
        if (VideoFileList.length > 0) {
            for (let i in VideoFileList) {
                let index = Number(i) + Number(videoList.length)
                formData.append('feed_resource_video[' + index + '][file]', VideoFileList[i].originFileObj);
            }
        }

        if (PropertiesIds.length > 0) {
            for (let i in PropertiesIds) {
                formData.append('feed_property[' + i + "][property_id]", PropertiesIds[i]);
            }
        }

        for (let [a, b] of formData.entries()) {
            console.log(a + ":", b)
        }

        if (ImgFileList.length > 0 || detail || videoList.length > 0 || otherFileList.length > 0 || VideoFileList.length > 0) {
            if (PropertiesIds.length > 0) {
                AddFeed(formData).then(res => {
                    setImgFileList([])
                    setImageList([])
                    setFeedList([])
                    setVideList([])
                    setVideoFileList([])
                    setOtherFileList([])
                    setAllList([])
                    setTagValue([])
                    setDetail("")
                    handleGetFeedList(1)
                }).catch(error => {
                    message.error("Upload failed, please try again.")
                })
            } else {
                message.error("Please select Properties")
            }

        }



    }


    const handleDeleteFeedItem = (id) => {
        deleteFeed(id).then(res => {
            handleGetFeedList(1)
        })
    }

    const handleGetFeedList = (page) => {
        GetFeedList(page).then(res => {
            console.log(res)
            let NewData = []
            let initData = res.data.data
            let pagination = res.data.meta.pagination
            if (page === 1) {
                NewData = initData
            } else {
                NewData = FeedList.concat(initData)
            }
            setFeedList(NewData)
            setTotalPage(pagination.total_pages)
            setPage(pagination.current_page)
            setLoading(false)
        })
    }

    const handleInfiniteOnLoad = () => {
        setLoading(true)
        if (page >= totalPage) {
            setLoading(false)
            setHasMore(false)
            return;
        }
        handleGetFeedList(page + 1)
    };


    const HandleChangeShow = (item) => {
        let NewData = [...FeedList]
        for (let i in NewData) {
            if (item.id === NewData[i].id) {
                if (NewData[i].isShow) {
                    NewData[i].isShow = false
                } else {
                    NewData[i].isShow = true
                }
            } else {
                NewData[i].isShow = false
            }
        }
        setFeedList(NewData)

    }

    const VideoComponent = (feedResourceVideo) => {
        let url = feedResourceVideo[0].url
        let youtubeUrl = url.indexOf("youtu")
        let TencentUrl = url.indexOf("v.qq.com")
        if (youtubeUrl > 0) {
            let num = url.indexOf("?v=");
            if (num > 0) {
                url = url.substring(num + 3, url.length);
            } else {
                let yNum = url.indexOf(".be/");
                url = url.substring(yNum + 4, url.length);
            }
            return <div style={{ margin: "5px", width: "100%" }} >
                <iframe width="100%" height="300" src={"https://www.youtube.com/embed/" + url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        } else if (TencentUrl > 0) {
            var video_Url_Id = "";
            let Pc_Tx_Id_w = url.substr(0, url.lastIndexOf('.'));
            video_Url_Id = Pc_Tx_Id_w.substr(Pc_Tx_Id_w.lastIndexOf('/') + 1, Pc_Tx_Id_w.length);
            return <div style={{ margin: "5px", width: "100%" }}>
                <iframe width="100%" src={"http://v.qq.com/iframe/player.html?vid=" + video_Url_Id + "&tiny=0&auto=0&isAutoPlay=false"} title="Tencent video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        } else {
            return <div style={{ margin: "5px", width: "100%" }}>
                <video src={url} style={{ width: "100%" }} controls />
            </div>
        }
    }



    return (
        <div style={{ display: "flex", flex: 1, height: Height + "px", justifyContent: "center", alignItems: "center", overflowX: "hidden", overflowY: "hidden" }}>
            <div style={{ width: "1270px", height: "100%", display: "flex" }}>
                <div style={{ width: "270px", height: "100%" }}>
                    <div style={{ width: "100%", marginTop: "20px", backgroundColor: "#fff", textAlign: "center" }}>
                        {/* <Link to="" style={{ fontSize: "20px", fontWeight: "800", color: "#000" }}>
                            Wharton Capital Station Manager Portal
                            </Link> */}
                        <span style={{ fontSize: "20px", fontWeight: "800" }}>Wharton Capital Station Manager Portal</span>
                    </div>
                    <Menu defaultSelectedKeys={['0']} style={{ marginTop: "20px" }}>
                        <MenuItem
                            key={0}
                            style={{ width: "250px", height: "50px", margin: "10px", backgroundColor: "#fff" }}>
                            <BorderlessTableOutlined />
                            <Link to="feeds" style={{ fontSize: "20px", fontWeight: "800" }}>
                                Station Posts
                            </Link>
                        </MenuItem>
                    </Menu>
                </div>
                <div style={{ width: "800px", height: "100%", border: "0.5px solid rgb(235, 238, 240)" }}>
                    <div style={{ width: "100%", height: "55px", borderBottom: "0.5px solid rgb(235, 238, 240)", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <span style={{ fontSize: "20px", fontWeight: "800", marginLeft: "15px" }}>Station Posts</span>
                        <Button type="ghost" size="middle"
                            style={{ fontSize: "18px", color: "#000", border: "none", outline: "0", boxShadow: "none" }}
                            onClick={() => {
                                localStorage.removeItem("username")
                                localStorage.removeItem('token')
                                window.location.href = '/'
                            }}>Logout</Button>
                    </div>
                    <div style={{ width: "100%", minHeight: "120px", borderBottom: "0.5px solid rgb(235, 238, 240)", display: "flex", justifyContent: "center" }}>
                        <div style={{ width: "97%" }}>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%', marginBottom: "10px", marginTop: "10px" }}
                                placeholder="Please select properties"
                                onChange={(item) => {
                                    setPropertiesIds(item)
                                }}
                            >
                                {
                                    PropertiesList.map((item, index) => {
                                        return <Option key={index} value={item.id}>{item.name}</Option>
                                    })
                                }

                            </Select>
                            <div style={{ minHeight: "68px", display: "flex", alignItems: "center" }}>
                                <TextArea
                                    style={{ fontSize: "24px", fontWeight: "500", minHeight: "48px" }}
                                    placeholder="What’s happening?"
                                    value={detail}
                                    bordered={false}
                                    autoSize={{ maxRows: 6 }}
                                    onChange={(e) => {
                                        e.persist()
                                        let key = e.target.value
                                        setDetail(key)
                                    }}
                                />
                            </div>
                            <div style={{ minHeight: "1px", display: "flex", flexWrap: "wrap" }}>
                                {
                                    ImageList.map((item, index) => {
                                        return <div style={{ margin: "5px", position: "relative" }} key={index}>
                                            <Button type="ghost" size="middle" icon={<DeleteOutlined />}
                                                style={{ fontSize: "18px", color: "#666", position: "absolute", top: "0px", right: "0px", border: "none", outline: "0", boxShadow: "none" }}
                                                onClick={() => handleDelete(index)}
                                            />
                                            <img src={item} style={{ width: "100px", height: "100px" }} alt="" />
                                        </div>
                                    })
                                }
                            </div>
                            <div style={{ width: "98%", minHeight: "1px", display: "flex", flexWrap: "wrap" }}>
                                {
                                    VideoFileList.map((item, index) => {
                                        return <div style={{ margin: "5px", position: "relative" }} key={index}>
                                            <Button type="ghost" size="middle" icon={<DeleteOutlined />}
                                                style={{ fontSize: "18px", color: "#666", position: "absolute", top: "0px", right: "0px", border: "none", outline: "0", boxShadow: "none" }}
                                                onClick={() => handleVideoFileDelete(index)}
                                            />
                                            <img src={videImg} style={{ width: "50px", height: "50px" }} alt="" />
                                        </div>
                                    })
                                }
                            </div>
                            <div style={{ width: "98%", minHeight: "1px", display: "flex", flexWrap: "wrap" }}>
                                {
                                    otherFileList.map((item, index) => {
                                        return <div style={{ margin: "5px", position: "relative" }} key={index}>
                                            <Button type="ghost" size="middle" icon={<DeleteOutlined />}
                                                style={{ fontSize: "18px", color: "#666", position: "absolute", top: "0px", right: "0px", border: "none", outline: "0", boxShadow: "none" }}
                                                onClick={() => handleOtherFileDelete(index)}
                                            />
                                            <img src={fileImg} style={{ width: "50px", height: "50px" }} alt="" />
                                        </div>
                                    })
                                }
                            </div>
                            <div style={{ width: "98%", minHeight: "1px", display: "flex", flexWrap: "wrap" }}>
                                {
                                    videoList.map((item, index) => {
                                        return <Tag
                                            key={index}
                                            closable
                                            style={{ marginTop: "5px", display: "flex", alignItems: "center" }}
                                            onClose={() => {
                                                let arr = [...videoList]
                                                arr.splice(index, 1)
                                                setVideList(arr)
                                            }}
                                        >
                                            <span style={{ maxWidth: "450px", height: "auto", wordWrap: "break-word", wordBreak: "break-all", overflow: "hidden" }}>{item}</span>
                                        </Tag>
                                    })
                                }
                            </div>
                            <div style={{ width: "98%", display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px" }}>
                                <div>
                                    <Button
                                        type="ghost"
                                        style={{ color: "#fff", border: "none", padding: "0px", marginRight: "15px" }}
                                        onClick={() => {
                                            setIsEnterUrl(!isEnterUrl)
                                        }}
                                    >
                                        <img src={iconVideo} style={{ width: "30px", height: "35px" }} alt="" />
                                    </Button>
                                    <Upload
                                        showUploadList={false}
                                        onChange={handleChange}
                                        fileList={AllList}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                    >
                                        <img src={iconFile} style={{ width: "25px", height: "25px", cursor: "pointer" }} alt="" />
                                    </Upload>

                                </div>
                                <div style={{ backgroundColor: "rgb(29, 161, 242)", padding: "0px 15px", borderRadius: "30px" }}>
                                    <Button
                                        type="ghost"
                                        style={{ fontSize: "18px", color: "#fff", fontWeight: "700", border: "none", padding: "0px" }}
                                        onClick={() => HandleSubmit()}
                                    >Submit</Button>
                                </div>

                            </div>
                            {
                                isEnterUrl &&
                                <div style={{ width: "100%", height: "40px", marginTop: "25px" }}>
                                    <Search
                                        placeholder="input video url"
                                        allowClear
                                        enterButton="Done"
                                        size="middle"
                                        value={tagValue}
                                        onInput={(e) => {
                                            e.persist()
                                            let key = e.target.value
                                            setTagValue(key)
                                        }}
                                        onSearch={(e) => {
                                            if (e) {
                                                let arr = [...videoList]
                                                arr.push(e)
                                                setVideList(arr)
                                                setTagValue("")
                                            }
                                        }}
                                    />
                                </div>
                            }




                        </div>
                    </div>
                    <div style={{ width: "100%", height: "10px", backgroundColor: "rgb(235,238,240)" }} />
                    <div style={{ width: "100%", height: Height - 195 + "px", overflow: "auto", paddingBottom: "50px", marginBottom: "10px" }} className="InfiniteScroll">
                        <InfiniteScroll
                            initialLoad={false}
                            // pageStart={0}
                            loadMore={handleInfiniteOnLoad}
                            hasMore={!loading && hasMore}
                            useWindow={false}
                        >
                            <List
                                dataSource={FeedList}
                                renderItem={item => {
                                    let feedResourceImage = item.feedResourceImage.data
                                    let feedResourceFile = item.feedResourceFile.data
                                    let feedResourceVideo = item.feedResourceVideo.data
                                    let PropertyData = item.property.data
                                    let date = item.created_at && item.created_at.date.substring(0, item.created_at.date.length - 7)
                                    return <List.Item key={item.id}>
                                        <div style={{ width: "100%", display: "flex" }}>
                                            <div style={{ width: "8%", display: "flex", justifyContent: "center" }}>
                                                <img style={{ width: "48px", height: "48px", borderRadius: "25px", marginTop: "10px" }} src={item.user.header_image} alt="" />
                                            </div>
                                            <div style={{ width: "90%", marginLeft: "1%" }}>
                                                <div style={{ marginBottom: "10px", display: 'flex', justifyContent: "space-between" }}>
                                                    <div>
                                                        <span style={{ fontSize: "18px", fontWeight: "700" }}>{item.create_by}</span>
                                                        <span style={{ fontSize: "14px", color: "#5e7284", marginLeft: "20px" }}>{date}</span>
                                                    </div>
                                                    {
                                                        !item.status &&
                                                        <Button type="ghost" size="middle" icon={<CloseOutlined />}
                                                            style={{ fontSize: "18px", color: "#666", border: "none", outline: "0", boxShadow: "none" }}
                                                            onClick={() => handleDeleteFeedItem(item.id)}
                                                        />
                                                    }

                                                </div>



                                                <div style={{ width: "100%", borderRadius: "10px", border: "1px solid #c4cfd6", marginBottom: "10px" }}>
                                                    <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                                                        {
                                                            PropertyData.map((img, index) => {
                                                                return <div style={{ margin: "5px", padding: "0 10px", backgroundColor: "#" + img.color }} key={index}>
                                                                    <span style={{ color: "#FFF" }}>{img.name}</span>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    <p style={{ color: "#5b7083", fontSize: "16px", marginLeft: "10px", marginBottom: "5px", width: "95%", height: "auto", wordWrap: "break-word", wordBreak: "break-all" }}>
                                                        {item.detail}
                                                    </p>

                                                    {
                                                        !item.isShow ?
                                                            <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                                                                {
                                                                    feedResourceVideo.length > 0 && VideoComponent(feedResourceVideo)
                                                                }
                                                                {
                                                                    feedResourceVideo.length === 0 && feedResourceImage.length > 0 &&
                                                                    <div style={{ margin: "5px 5px 0px 5px", width: "100%" }}>
                                                                        <img src={feedResourceImage[0].url} style={{ width: "100%" }} alt="" />
                                                                    </div>
                                                                }
                                                                {
                                                                    feedResourceVideo.length === 0 && feedResourceImage.length === 0 && feedResourceFile.length > 0 &&
                                                                    <div style={{ margin: "5px" }}>
                                                                        <a href={feedResourceFile[0].url} target="_blank" rel="noopener noreferrer">
                                                                            <img src={fileImg} style={{ width: "50px", height: "50px" }} alt="" />
                                                                        </a>
                                                                    </div>
                                                                }

                                                            </div> :

                                                            <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                                                                {
                                                                    feedResourceVideo.map((img, index) => {
                                                                        let url = img.url
                                                                        let youtubeUrl = url.indexOf("youtu")
                                                                        let TencentUrl = url.indexOf("v.qq.com")
                                                                        if (youtubeUrl > 0) {
                                                                            let num = url.indexOf("?v=");
                                                                            if (num > 0) {
                                                                                url = url.substring(num + 3, url.length);
                                                                            } else {
                                                                                let yNum = url.indexOf(".be/");
                                                                                url = url.substring(yNum + 4, url.length);
                                                                            }
                                                                            return <div style={{ margin: "5px" }} key={index}>
                                                                                <iframe width="700" height="300" src={"https://www.youtube.com/embed/" + url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                            </div>
                                                                        } else if (TencentUrl > 0) {
                                                                            var video_Url_Id = "";
                                                                            let Pc_Tx_Id_w = url.substr(0, url.lastIndexOf('.'));
                                                                            video_Url_Id = Pc_Tx_Id_w.substr(Pc_Tx_Id_w.lastIndexOf('/') + 1, Pc_Tx_Id_w.length);
                                                                            return <div style={{ margin: "5px" }} key={index}>
                                                                                <iframe width="700" height="300" src={"http://v.qq.com/iframe/player.html?vid=" + video_Url_Id + "&tiny=0&auto=0&isAutoPlay=false"} title="Tencent video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                            </div>
                                                                        } else {
                                                                            return <div style={{ margin: "5px" }} key={index}>
                                                                                <video src={url} style={{ width: "700px" }} controls />
                                                                            </div>
                                                                        }
                                                                    })
                                                                }
                                                                {
                                                                    feedResourceImage.map((img, index) => {
                                                                        return <div style={{ margin: "7px", position: "relative" }} key={index}>
                                                                            <img src={img.url} style={{ width: "700px" }} alt="" />
                                                                        </div>
                                                                    })
                                                                }

                                                                {
                                                                    feedResourceFile.map((img, index) => {
                                                                        return <div style={{ margin: "5px" }} key={index}>
                                                                            <a href={img.url} target="_blank" rel="noopener noreferrer">
                                                                                <img src={fileImg} style={{ width: "50px", height: "50px" }} alt="" />
                                                                            </a>
                                                                        </div>
                                                                    })
                                                                }

                                                            </div>
                                                    }
                                                </div>

                                                <span style={{ color: "#1890ff", cursor: "pointer", }} onClick={() => {
                                                    HandleChangeShow(item)
                                                }}>
                                                    {item.isShow ? "Close" : " Show More"}
                                                </span>
                                            </div>
                                        </div>
                                    </List.Item>
                                }}
                            >
                                {loading && hasMore && (
                                    <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Spin />
                                    </div>
                                )}
                            </List>
                        </InfiniteScroll>
                    </div>
                </div>
                {/* <div style={{ width: "200px" }}>
                    <div style={{ width: "100%", height: "55px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Button type="ghost" size="middle"
                            style={{ fontSize: "18px", color: "#000", border: "none", outline: "0", boxShadow: "none" }}
                            onClick={() => {
                                localStorage.removeItem("username")
                                localStorage.removeItem('token')
                                window.location.href = '/'
                            }}>Logout</Button>
                    </div>
                    <div style={{ width: "90%", height: "auto", backgroundColor: "#f7f9fa", marginLeft: "5%", borderRadius: "10px" }}>
                        <div style={{ width: "100%", height: "55px", display: "flex", alignItems: "center" }}>
                            <span style={{ fontSize: "20px", fontWeight: "800", marginLeft: "15px" }}>Properties</span>
                        </div>
                        {
                            PropertiesList.map((item, index) => {
                                return <div key={index} style={{ width: "100%", height: "55px", borderTop: "0.5px solid rgb(235, 238, 240)", display: "flex", alignItems: "center" }}>
                                    <span style={{ fontSize: "16px", fontWeight: "600", marginLeft: "30px" }}>{item.name}</span>
                                </div>
                            })
                        }
                    </div>
                </div> */}
            </div>
        </div>
    );

}

export default Feeds

