import React, { Component } from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import './index.less'
// import {getAdminDetail} from "../../action/authAction";
import {removeToken} from "../../util/function";

class Header extends Component {
  state = {
    userName: '',
  }

  async componentDidMount() {
    let username=localStorage.getItem("username")

    this.setState({
      userName:username
    })
  }


  logout = () => {
    removeToken()
    localStorage.removeItem("username")
    window.location.href = '/'
  }


  render() {
    return (
      <div className='header'>
        <Row className='header-top'>
          <Col span={24}>
            <span>{this.state.userName}</span>
            <span className='logout' onClick={this.logout}>
              Logout
            </span>
          </Col>
        </Row>
        {/* <Row className='breadcrumb'>
          <Col className='breadcrumb-title' span={4}>
            {this.props.menuName}
          </Col>

        </Row> */}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    menuName: state.name.menuName
  }
}

export default connect(mapStateToProps)(Header)
