import axios from 'axios'
import { API } from "./api";
import { getTokenStorage } from "./function"
import { message } from "antd";


axios.defaults.baseURL = API.BASEURL;
let ajaxLoading = document.getElementById('ajaxLoading')

// axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';


export class Axios {

    static ajax(options) {
        if (!options.closeLoading) {
            ajaxLoading.style.display = 'block'
        }

        return new Promise((resolve, reject) => {
            axios({
                method: options.method ? options.method : 'get',
                url: options.url,
                data: options.data || "",
                headers: {
                    'Authorization': getTokenStorage() && !!!options.noToken ? "Bearer " + getTokenStorage() : ""
                },
            }).then(res => {
                ajaxLoading.style.display = 'none'
                if (options.tips) {
                    message.success(options.tips)
                }
                resolve(res)
            }).catch(e => {
                if (e.response && e.response.data && e.response.data.status_code === 401) {
                    message.error(e.response.data.message)
                }
                if (!options.closeLoading) {
                    ajaxLoading.style.display = 'none'
                }
                reject(e)
            })
        })

    }


}

// 添加响应拦截器
axios.interceptors.response.use(
    function (response) {

        return response
    },
    function (error) {
        console.log(error.response)
        if (error.response) {
            localStorage.removeItem('token')
            window.location.href = '/'
        }
        return Promise.reject(error)
    }
)

