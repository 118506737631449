import React, { useState, useEffect } from "react";
import { Input, Button, Form } from 'antd';
import { UpdateUser, GetUserDetail } from "../action/authAction";



const EditUser = () => {
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 20, span: 16 },
    };

    const [data, setData] = useState("")




    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        GetUserDetail().then(res => {
            setData(res.data)
        })
    }


    const onFinish = (values) => {
        if (!values.password) {
            delete values.password
        }
        UpdateUser(values).then(res => {
            getData()
        }).catch(error=>{
            console.log(error)
        })
    };

    if (data) {
        return (
            <div style={{ width: "100%", backgroundColor: "#fff", textAlign: "center" }}>

                <div style={{ width: "50%", marginTop: "100px" }}>
                    <Form
                        {...layout}
                        name="basic"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Username"
                            name="name"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                            initialValue={data.name}
                        >
                            <Input disabled={true}/>
                        </Form.Item>

                        <Form.Item
                            label="Password"
                            name="password"
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
    return null


}

export default EditUser

